/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.create-pre_delivery-form {
  margin: 0 0 10px 0;
}
.main-header {
  text-align: center;
}
.background {
  background: white;
}
.mobile .background {
  padding: 12px 16px;
}
.next-button {
  margin: 10px 0 0 0;
}
.pre_delivery-subsection {
  margin-left: 64px;
  margin-bottom: 32px;
}
.mobile .pre_delivery-subsection {
  margin-left: 16px;
}
.pre_delivery-subsection-full {
  margin-bottom: 32px;
}
.pre_delivery-row {
  margin-bottom: 12px;
}
.pre_delivery-row label {
  color: #1890ff;
}
.mobile .pre_delivery-row label {
  margin-bottom: 4px;
}
.pre_delivery-input-block {
  display: inline-block;
  margin-right: 8px;
}
.mobile .pre_delivery-input-block {
  display: block;
  margin-bottom: 4px;
  margin-right: 0;
}
.files-component {
  margin-right: 2rem;
}
.files-upload {
  display: flex;
  flex-direction: row;
  margin-right: 2rem;
}
.page-action-right {
  float: right;
  margin-bottom: 8px;
}
