/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_delivery-checklist {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.pre_delivery-checklist .pre_delivery-checklist-block {
  flex-grow: 1;
  flex-basis: 325px;
  min-width: 325px;
  max-width: 400px;
  margin-bottom: 24px;
  padding-right: 16px;
}
.mobile .pre_delivery-checklist .pre_delivery-checklist-block {
  max-width: 100%;
  padding-right: 0;
  flex-basis: 275px;
  min-width: 275px;
}
.pre_delivery-checklist .checklist-custom {
  display: flex;
  justify-content: left;
  border-bottom: 1px dashed #d9d9d9;
}
.pre_delivery-checklist .checklist-custom:last-child {
  border-bottom: none;
}
.pre_delivery-checklist .return-equiptment {
  display: flex;
  justify-content: left;
  margin-top: 8px;
}
.pre_delivery-checklist .checklist-pair {
  display: flex;
  justify-content: left;
  border-bottom: 1px dashed #d9d9d9;
}
.pre_delivery-checklist .checklist-pair:last-child {
  border-bottom: none;
}
.pre_delivery-checklist .checklist-pair .checklist-label {
  display: flex;
  flex-basis: 80%;
  justify-content: left;
}
.pre_delivery-checklist .checklist-pair .checklist-item {
  display: flex;
  flex-basis: 10%;
  justify-content: center;
}
@media print {
  .checklist-row > label {
    -webkit-print-color-adjust: exact;
  }
}
