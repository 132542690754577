/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.pre_delivery-checklist {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.pre_delivery-checklist .pre_delivery-general-input {
  width: 60px;
  margin-right: 4px;
  font-size: 0.9em;
}
.pre_delivery-checklist .pre_delivery-flight-input {
  width: 40px;
  margin-right: 4px;
  font-size: 0.9em;
}
.pre_delivery-checklist .pre_delivery-return-input {
  width: 180px;
  margin-right: 4px;
  font-size: 0.9em;
}
.pre_delivery-checklist .pre_delivery-checklist-block {
  flex-grow: 1;
  flex-basis: 325px;
  min-width: 325px;
  max-width: 400px;
  margin-bottom: 24px;
  padding-right: 16px;
}
.mobile .pre_delivery-checklist .pre_delivery-checklist-block {
  max-width: 100%;
  padding-right: 0;
  flex-basis: 275px;
  min-width: 275px;
}
