/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.consent-check {
  display: flex;
}
.sign-area {
  display: block;
  align-items: center;
  width: 123.16px;
  height: 40px;
}
.pre-delivery-modal {
  height: 600px;
  overflow: auto;
  overscroll-behavior: none;
}
.pre-delivery-modal div {
  font-size: 0.7rem;
}
.page-header {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
}
.service-detail-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-bottom: 8px;
  border-radius: 4px;
}
.service-detail-container .service-col {
  flex-basis: 50%;
  display: flex;
  flex-wrap: nowrap;
  min-width: 0;
  border: 1px solid #e8e8e8;
}
.service-detail-container .service-col > label {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  color: #14837B;
  min-width: 100px;
  flex-basis: 100px;
  background-color: #f5f5f5;
  padding: 4px 8px;
}
.service-detail-container .service-col > .service-val {
  flex-grow: 1;
  padding: 4px 8px;
  min-width: 0;
}
.service-detail-container .service-col > .service-val .service-val-row {
  margin-bottom: 2px;
}
.service-detail-container .service-col > .service-val .service-val-row:last-child {
  margin-bottom: 0;
}
.consent {
  margin-top: 16px;
}
.signature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  margin-top: 8px;
  margin-bottom: 0px;
}
.signature .signature-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.signature .sign-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signature-img {
  display: block;
  width: 100%;
}
.page-remark {
  display: flex;
  margin-top: 8px;
  justify-content: left;
  align-items: left;
}
